import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"

const Tabs = ({ tabsContentArray = [], ...args }) => {
  const [activeTab, setActiveTab] = useState(0)

  const navigationTabs = []
  const tabSections = []

  for (let i = 0; i < tabsContentArray.length; ++i) {
    const tabClassName = `tab-${i == activeTab ? "active" : "inactive"}`
    const tabIdSet = `${i}Section`
    const tabHrefTag = `#${tabIdSet}`

    navigationTabs.push(
      <a
        role={"button"}
        key={tabHrefTag}
        className={tabClassName}
        onClick={(e) => {
          setActiveTab(i)
          e.preventDefault()
        }}
        href={tabHrefTag}
      >
        {tabsContentArray[i].tabHeading}
      </a>
    )

    const tabContent = tabsContentArray[i].tabContent
    let tabSectionsChildren = null
    let tabSectionsHtmlString = null
    if (typeof tabContent === "string" || tabContent instanceof String) {
      tabSectionsHtmlString = { __html: tabContent }
    } else {
      tabSectionsChildren = tabContent
    }
    tabSections.push(
      <div
        key={tabIdSet}
        className={`${tabClassName} tab-content-container`}
        id={tabIdSet}
      >
        {tabSectionsChildren || (
          <div dangerouslySetInnerHTML={tabSectionsHtmlString} />
        )}
      </div>
    )
  }

  return (
    <TabsStylingWrapper {...args}>
      <NavigationStylingWrapper className="tab-nav-wrapper">
        {navigationTabs}
      </NavigationStylingWrapper>
      {tabSections}
    </TabsStylingWrapper>
  )
}

export default Tabs

const NavigationStylingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const TabsStylingWrapper = styled.div`
  div.tab-active {
    display: flex;
  }
  div.tab-inactive {
    display: none;
  }
`
