import styled from "styled-components"

const InnerContentArea = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${({ theme }) => theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
  h3 {
    text-transform: none;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: ${({ theme }) => theme.breakMedium};
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    max-width: 100%;
    padding: 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 100%;
    padding: 0 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-top: 4.5rem;
  }
`

export default InnerContentArea
