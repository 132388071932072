import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import CloudflareImage from "../../components/reusable-components/cloudflare-image"

import BackgroundImage from "gatsby-background-image"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"
import FlexSection from "../../components/reusable-components/layout/flex-section"
import InnerContentArea from "../../components/reusable-components/layout/inner-content-area"
import Tabs from "../../components/reusable-components/layout/tabs"

import { getProperty } from "../../lib/utils"

const OurTeamPage = ({ pageContext, location }) => {
  const ourTeamPageQueryData = useStaticQuery(OUR_TEAM_PAGE_QUERY)
  const title = getProperty(ourTeamPageQueryData, ["wpPage", "title"], "")
  const content = getProperty(ourTeamPageQueryData, ["wpPage", "content"], "")

  const ourTeamBannerImg = getProperty(
    ourTeamPageQueryData,
    ["wpPage", "featuredImage", "node"],
    false
  )

  const branchLocations = getProperty(
    ourTeamPageQueryData,
    ["wpPage", "acf_our_team_page", "ourTeamBranchLocationRepeater"],
    []
  )

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO
        title={getProperty(
          ourTeamPageQueryData,
          ["seaPage", "seo", "title"],
          ""
        )}
        location={location}
        yoast={getProperty(ourTeamPageQueryData, ["seaPage", "seo"], {})}
      />
      <OurTeamBannerStyledSection className={"page-fullwidth-section"}>
        <StyledBackgroundImage
          fluid={
            ourTeamBannerImg && ourTeamBannerImg.localFile.childImageSharp.fluid
          }
        >
          <InnerContentArea>
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </InnerContentArea>
        </StyledBackgroundImage>
      </OurTeamBannerStyledSection>
      <StyledTabs
        className="styled-tabs"
        tabsContentArray={branchLocations.map((item, index) => {
          const tabContentTeamMembers = item.ourTeamMembersRepeater.map(
            (teamMember, teamMemberIndex) => {
              const teamMemberFluid = getProperty(
                teamMember,
                ["ourTeamMemberPhoto", "sourceUrl"],
                false
              )
              return (
                <TeamMemberCardStyling key={teamMemberIndex}>
                  <CloudflareImage
                    objectFit="cover"
                    objectPosition="50% 0"
                    src={teamMemberFluid}
                  />
                  <h5>{teamMember.ourTeamMemberName}</h5>
                  <p>{teamMember.ourTeamMemberJobTitle}</p>
                </TeamMemberCardStyling>
              )
            }
          )
          return {
            tabContent: tabContentTeamMembers,
            tabHeading: item.branchLocation,
          }
        })}
      />
    </Layout>
  )
}

export default OurTeamPage

// ===============
//     STYLES
// ===============

const StyledTabs = styled(Tabs)`
  .tab-content-container {
    flex-wrap: wrap;
  }
  a {
    width: 32%;
    max-width: 400px;
    text-align: center;
    border: 2px solid ${({ theme }) => theme.colors.lightgrey3};
    border-radius: 50px;
    padding: 1rem 0;
    transition: all 0.15s ease-in-out;
    color: ${({ theme }) => theme.colors.black1};
    background: ${({ theme }) => theme.colors.lightgrey3};
  }

  a.tab-active {
    background: ${({ theme }) => theme.colors.grey};
    border: 2px solid ${({ theme }) => theme.colors.grey};
    color: white;
    transition: all 0.15s ease-in-out;
  }
  .tab-nav-wrapper {
    margin-bottom: 100px;
    text-transform: uppercase;
  }
`

const OurTeamBannerStyledSection = styled(FlexSection)`
  margin-top: 1rem;
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  & > div {
    width: 100%;
  }
  h1,
  h3 {
    color: white;
  }
`
const StyledBackgroundImage = styled(BackgroundImage)`
  background-color: ${({ theme }) => theme.colors.blue}44;
  opacity: 1 !important;
  height: 350px;
`

const TeamMemberCardStyling = styled(FlexSection)`
  width: 400px;
  align-items: center;
  &:nth-child(3n + 2) {
    margin: 0 1.6% 2.5%;
  }
  margin: 0 0 2.5%;
  padding-bottom: 1.8rem;
  text-align: center;
  h5 {
    padding-top: 1.8rem;
    text-align: center;
    text-transform: none;
  }
  background: ${({ theme }) => theme.colors.lightgrey1};
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 47%;
    margin: 0 1.4% 2.5%;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
`

const StyledImg = styled(CloudflareImage)`
  height: 400px;
  width: 100%;
  img {
    left: unset;
    right: 0;
  }
  & div {
    padding-bottom: 100% !important;
  }
`

// ===============
//      QUERY
// ===============
const OUR_TEAM_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "our-team" }) {
      acf_our_team_page {
        ourTeamBranchLocationRepeater {
          branchLocation
          ourTeamMembersRepeater {
            ourTeamMemberJobTitle
            ourTeamMemberName
            ourTeamMemberPhoto {
              sourceUrl
            }
          }
        }
      }
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    seaPage(slug: { eq: "our-team" }) {
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
